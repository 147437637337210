import { useEffect } from 'react';

// Leaflet
import {
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    useMap
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css'

// Marker icon
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function LocationMarker(props) {
    return (
        <Marker position={props.location}>
            <Popup>
                You are here!
            </Popup>
        </Marker>
    );
}

function ShopMarkers(props) {
    const map = useMap();

    useEffect(() => {
        map.fitBounds([props.location, props.shopLocations[0].location]);
    });

    return props.shopLocations.map(s => {
        return (
            <Marker key={s.name} position={s.location}>
                <Popup>
                    {s.fullName}
                </Popup>
            </Marker>
        );
    });
}

function Map(props) {
    return (
        <MapContainer center={props.location}
                      zoom={14}
                      scrollWheelZoom={false}
                      style={{height: props.height}}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker location={props.location} />
            <ShopMarkers location={props.location}
                         shopLocations={props.shopLocations} />
        </MapContainer>
    );
}

export { Map };