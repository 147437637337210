import { Component } from 'react';

// Bootstrap
import Container from 'react-bootstrap/Container';

import { ShopLocations } from './ShopLocations';

class CurrentLocation extends Component {
    constructor(props) {
        super(props);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.state = {location: this.props.location};
    }

    handleLocationChange(location) {
        this.setState({location: location});
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition((position) => {
            this.handleLocationChange([
                position.coords.latitude,
                position.coords.longitude]);
        });
    }

    render() {
        return (
            <Container fluid>
                <ShopLocations location={this.state.location}
                               height={this.props.height}/>
            </Container>
        );
    };
}

export { CurrentLocation };