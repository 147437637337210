// Boostrap
import Container from 'react-bootstrap/Container';

import { Navigation } from './components/Navigation';
import { CurrentLocation } from './components/CurrentLocation';
import './App.css';

function App() {
    const DEFAULT_LOCATION = [42.317604822407304, -72.63315903599037];

    return (
        <Container fluid={true}>
            <Navigation title={'CannaWalk'}/>
            <CurrentLocation location={DEFAULT_LOCATION}
                             height={'300px'} />
        </Container>
    );
}

export default App;
