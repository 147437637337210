// Bootstrap
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';

function ShopList(props) {
    function buildAppleUrl(location, distance) {
        return 'https://maps.apple.com/?daddr=' +
            (location.fullName + ' ' + location.address)
                .replace(' ', '+')
            + (distance <=3 ? '&dirflag=w' : '&dirflag=d');
    }

    function buildGoogleUrl(location, distance) {
        return 'https://www.google.com/maps/dir/?api=1&destination=' +
            (location.fullName + ' ' + location.address)
                .replace(' ', '+')
            + (distance <=3 ? '&travelmode=walking' : '&travelmode=driving');
    }

    function isMac(){
        return navigator.platform.indexOf("Mac") === 0
            || navigator.platform.indexOf("iPhone") === 0
            || navigator.platform.indexOf("iPad") === 0;
    }

    const cards = props.shopLocations.map(l => {
        return (
            <ListGroup.Item key={l.name} action
                            href={isMac() ? buildAppleUrl(l, l.distance) : buildGoogleUrl(l, l.distance)}>
                <Row>
                    <Col>
                        {l.fullName} &nbsp;
                        <OverlayTrigger
                            key={l.name}
                            placement={'right'}
                            delay={500}
                            overlay={
                                <Tooltip id={`tooltip-${l.name}`}>
                                    ~{l.distance} miles
                                </Tooltip>
                            }>
                            <Badge bg="info">{
                                l.distanceDescription}
                            </Badge>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    });

    return (
        <Card>
            <Card.Body>
                <Card.Title>Nearby Shops</Card.Title>
                <Card.Text>
                    Click on any of the shops below for directions.
                </Card.Text>
            </Card.Body>
            <ListGroup variant={'flush'}>
                {cards}
            </ListGroup>
        </Card>
    );
}

export { ShopList };