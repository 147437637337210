// Bootstrap
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function Navigation(props) {
    return (
        <Navbar bg="light" stick="top">
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src="/cannabis-512.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    {props.title}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            </Container>
        </Navbar>
    );
}

export { Navigation };