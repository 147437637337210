// Bootstrap
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Map } from './Map';
import { ShopList } from './ShopList';

import HaversineGeolocation from 'haversine-geolocation';

function calculateDistance(location1, location2) {
    return HaversineGeolocation.getDistanceBetween(
        {latitude: location1[0], longitude: location1[1]},
        {latitude: location2[0], longitude: location2[1]}, 'mi');
}

function ShopLocations(props) {
    const locations = [
        {
            name: 'The Hempest',
            fullName: 'The Hempest Weed Dispensary Northampton',
            address: '2 Conz St, Northampton, MA 01060',
            location: [42.31639907908696, -72.63072117313719]
        }, {
            name: 'Resinate',
            fullName: 'Resinate Cannabis Dispensary Northampton MA',
            address: '110 Pleasant St, Northampton, MA 01060',
            location: [42.3187994405246, -72.62782632895745]
        }, {
            name: 'NETA',
            fullName: 'NETA Northampton',
            address: '118 Conz St, Northampton, MA 01060',
            location: [42.31282825738888, -72.6253208731374]
        }, {
            name: 'Insa',
            fullName: 'Insa Easthampton',
            address: '122 Pleasant St Suite 144, Easthampton, MA 01027',
            location: [42.27351750869675, -72.66223945964622]
        }
    ];

    function calculateDistanceDescription(distance) {
        if (distance <= 1) {
            return 'A Short Walk';
        }
        if (distance <= 2) {
            return 'A Bit of A Hike';
        }
        if (distance <= 3) {
            return 'A Long, Long Walk';
        }
        return 'Take a Car';
    }

    const shopLocations = locations.map(l => {
        l.distance = calculateDistance(props.location, l.location);
        l.distanceDescription = calculateDistanceDescription(l.distance);
        return l;
    }).sort((a, b) =>
        a.distance - b.distance);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Map location={props.location}
                         height={props.height}
                         shopLocations={shopLocations} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ShopList location={props.location}
                              shopLocations={shopLocations} />
                </Col>
            </Row>
        </Container>
    );
}

export { ShopLocations };